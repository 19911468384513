import { Controller } from '@hotwired/stimulus'
import { useDebounce } from 'stimulus-use'
import { patch } from '@rails/request.js'

export default class extends Controller {
  static values = { updateDelay: Number, updateUrl: String }
  static debounces = ['update']
  static targets = ['field', 'spinner', 'success', 'failure', 'warning']

  connect() {
    useDebounce(this, { wait: this.updateDelayValue })
  }

  clear() {
    [this.spinnerTarget, this.failureTarget].forEach(element => {
      element.classList.add('hidden')
    })
  }

  showButtons() {
    this.hide(this.spinnerTarget)

    if (this.fieldTarget.value !== '') {
      this.show(this.successTarget)
    } else {
      this.hide(this.successTarget)
    }
  }

  clearField() {
    const startNumber = this.fieldTarget.defaultValue

    this.fieldTarget.value = startNumber
    this.hide(this.successTarget)
  }

  async update() {
    this.hide(this.successTarget)
    this.show(this.spinnerTarget)

    const response = await this.makeRequest()
    this.hide(this.spinnerTarget)

    if (response.ok) {
      if (this.fieldTarget.value !== '') {
        this.show(this.successTarget)
      } else {
        this.show(this.warningTarget)
        this.hide(this.successTarget)
      }
    } else {
      this.show(this.failureTarget)
      this.hide(this.successTarget)
    }
  }

  async makeRequest(field) {
    const formData = new FormData();

    if (field instanceof PointerEvent) {
      // Called directly from HTML element using Stimulus action, null it
      field = field.target
      formData.append(field.name, field.value)

      await patch(this.updateUrlValue, { body: formData })

      this.dispatch("patch-done") // dispatch independently of response status
    } else if(field) {
      // Called internally within this controller
      formData.append(field.name, field.value)

      return patch(this.updateUrlValue, { body: formData })
    } else {
      // Called internally within this controller
      formData.append(this.fieldTarget.name, this.fieldTarget.value)

      return patch(this.updateUrlValue, { body: formData })
    }
  }

  show(element) {
    element.classList.remove('hidden')
  }

  hide(element) {
    element.classList.add('hidden')
  }
}
